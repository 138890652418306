import { get, put, post, remove } from '@/helpers/http';

export function getRecurrencesById(id) {
  return get(`/recurrences/${id}`);
}

export function updateRecurrenceInformation(recurrence) {
  return put(`/recurrences/${recurrence.id}`, recurrence);
}

export function createNewRecurrence(recurrence) {
  return post('/recurrences/', recurrence);
}

export function removeRecurrence(recurrence) {
  return remove(`/recurrences/${recurrence.id}`);
}

export function fetchRecurrences(pagination = {}) {
  return get('/recurrences', { ...pagination });
}
