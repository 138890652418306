<template>
  <getecma-form v-if="recurrence" :submit="save">

    <fieldset class="form-group mt--xl">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="recurrence.number"
            label="Número*"
            name="número"
            type="number"
            rules="required"
            placeholder="ex.: Dom Bosco" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            label="Frequência*"
            name="frequência"
            rules="required"
            :value="currentFrequency"
            placeholder="Selecione a fequência"
            :options="FREQUENCIES"
            @on-change="onFrequencyChange">
          </getecma-select>
        </div>
      </div>
    </fieldset>
    <div class="mt--xl mb--md d--flex justify-content-end">
      <getecma-button
        size="xl"
        :round="false"
        class="fs--sm"
        @click="goHistoryBack()">
        Cancelar
      </getecma-button>
      <getecma-button
        native-type="submit"
        class="ms--md fs--sm"
        bg-color="success"
        :round="false"
        size="xl">
        Salvar
      </getecma-button>
    </div>
  </getecma-form>
</template>

<script>
import { FREQUENCIES } from '@/modules/recurrence/recurrence.constants';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';

export default {
  name: 'GetecmaRecurrenceCreateInformation',
  components: {
  },
  inject: ['recurrenceEditVm'],
  data() {
    return {
      recurrence: this.recurrenceEditVm.recurrence,
      performer: getters.getUser(),
      FREQUENCIES,
      currentFrequency: Object.values(FREQUENCIES)[this.recurrenceEditVm.recurrence.frequency - 1].value,
    };
  },
  methods: {
    goHistoryBack,
    save() {
      this.$emit('save');
    },
    onFrequencyChange(frequency) {
      this.recurrence.frequency = frequency.key;
    },
  },
};
</script>
