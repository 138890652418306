import * as recurrenceResource from '@/modules/recurrence/recurrence.resource';

export function fetchRecurrences(pagination = {}) {
  return new Promise((resolve, reject) =>
    recurrenceResource
      .fetchRecurrences(pagination)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function getRecurrenceById(id) {
  return new Promise((resolve, reject) =>
    recurrenceResource
      .getRecurrencesById(id)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function createNewRecurrence(recurrence) {
  return new Promise((resolve, reject) =>
    recurrenceResource
      .createNewRecurrence(recurrence)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}

export function updateRecurrenceInformation(recurrence) {
  return new Promise((resolve, reject) =>
    recurrenceResource
      .updateRecurrenceInformation(recurrence)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
export function removeRecurrence(recurrence) {
  return new Promise((resolve, reject) =>
    recurrenceResource
      .removeRecurrence(recurrence)
      .then(({ data }) => resolve(data))
      .catch(err => reject(err)));
}
