<template>
  <div>
    <getecma-form v-if="recurrence" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="recurrence.number"
            label="Número*"
            name="número"
            type="number"
            rules="required"
            placeholder="ex.: 15" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            label="Frequência*"
            name="frequência"
            rules="required"
            placeholder="Selecione a fequência"
            :options="FREQUENCIES"
            @on-change="onFrequencyChange">
          </getecma-select>
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { FREQUENCIES } from '@/modules/recurrence/recurrence.constants';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';

export default {
  name: 'GetecmaRecurrenceCreateInformation',
  components: {
  },
  inject: ['recurrenceEditVm'],
  data() {
    return {
      recurrence: this.recurrenceEditVm.recurrence,
      performer: getters.getUser(),
      FREQUENCIES,
    };
  },
  methods: {
    goHistoryBack,
    create() {
      this.$emit('create');
    },
    onFrequencyChange(frequency) {
      this.recurrence.frequency = frequency.key;
    },
  },
};
</script>
