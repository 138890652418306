<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="recurrence" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="recurrence.id">
        <getecma-header size="lg">Editar Recorrência</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-recurrence-edit-information @save="save" />
      </div>
      <div v-if="!recurrence_id">
        <getecma-header size="lg">Adicionar Recorrência</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-recurrence-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenRecurrence, goToRecurrenceForbiddenPage } from '@/modules/recurrence/recurrence.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getRecurrenceById, updateRecurrenceInformation, createNewRecurrence } from '@/modules/recurrence/recurrence.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { RECURRENCES_URL } from '@/modules/recurrence/recurrence.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaRecurrenceEditInformation from '@/modules/recurrence/components/RecurrenceEditComponent.vue';
import GetecmaRecurrenceCreateInformation from '@/modules/recurrence/components/RecurrenceCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaRecurrenceEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaRecurrenceEditInformation,
    GetecmaRecurrenceCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const recurrenceEditVm = {};
    Object.defineProperty(recurrenceEditVm, 'recurrence', {
      get: () => this.recurrence,
    });
    return { recurrenceEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Recorrências', path: RECURRENCES_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      recurrence: null,
      createdRecurrence: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    recurrence_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.recurrence_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToRecurrenceForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToRecurrenceForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.recurrence_id) {
        getRecurrenceById(this.recurrence_id)
          .then(data => {
            this.recurrence = data;
          })
          .catch(() => toastError('Erro ao obter recorrência por ID'));
      } else {
        this.recurrence = {
          name: '',
        };
      }
    },
    save() {
      if (this.recurrence_id) {
        this.fullscreenLoading = true;
        updateRecurrenceInformation(this.recurrence)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Recorrência salva!');
            goToOpenRecurrence(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil da recorrência');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewRecurrence(this.recurrence);
      }
    },
    onCreateNewRecurrence(recurrence) {
      createNewRecurrence(recurrence)
        .then(data => {
          goToOpenRecurrence(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar a recorrência');
        });
    },
  },
};
</script>
